import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import classNames from 'classnames';
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import LoadingSpinner from '../../components/LoadingSpinner';
import Select from '../../components/Select';
import RadioGroup from '../../components/RadioGroup';
import s from './JobDetails.module.scss';
import AttachmentPinIcon from './Attachment-pin.svg';

const propTypes = {
  jobId: PropTypes.string.isRequired,
  title: PropTypes.string,
  location: PropTypes.string,
  employmentType: PropTypes.string,
  description: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.shape({})),
  educationRequired: PropTypes.bool,
};

const defaultProps = {
  title: '',
  location: '',
  employmentType: '',
  description: '',
  questions: [],
  educationRequired: false,
};

const JobDetails = ({
  jobId,
  title,
  location,
  employmentType,
  description,
  questions,
  educationRequired,
}) => {
  const { GATSBY_GREENHOUSE_BASE_URL } = process.env;
  const { GATSBY_GREENHOUSE_API_KEY } = process.env;
  const { GATSBY_LAMBDA_GREENHOUSE_PROXY } = process.env;
  const hiddenFileInput = useRef(null);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [educations, setEducations] = useState([{ value: null }]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [requiredFields, setRequiredFields] = useState({});
  const [degrees, setDegrees] = useState([]);

  // get base64 encoded string from file.
  const getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.onload = () => {
      cb(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // handles textfield onChange event.
  const onTextInputChange = (value, key) => {
    setErrorMessage('');
    setFormErrors({ ...formErrors, [key]: false });
    setFormData({ ...formData, [key]: value });
  };

  // handles textfield onBlur event.
  const onBlur = (value, key, isRequired) => {
    if (isRequired && !value) {
      setFormErrors({ ...formErrors, [key]: true });
    }
  };

  // handles education dropdown onChange event.
  const onEducationValueChange = (value, index) => {
    setErrorMessage('');
    educations[index].error = false;
    educations[index].value = value;
    setEducations([...educations]);
  };

  // handles resume upload button click event.
  const handleResumeSubmit = () => {
    hiddenFileInput.current.click();
  };

  // handles hidden input onChange event for resume file.
  const handleHiddenInputChange = (e) => {
    setErrorMessage('');
    const fileUploaded = e.target.files[0];
    if (fileUploaded !== undefined) {
      setFormErrors({ ...formErrors, resume: false });
      getBase64(fileUploaded, (result) => {
        setFormData({
          ...formData,
          resume_content: result.substring(28),
          resume_content_filename: fileUploaded.name,
        });
      });
    }
  };

  // returns input field based on question type.
  const getInputField = (question) => {
    // skip questions with field name from common questions array.
    const commonQuestions = ['first_name', 'last_name', 'email', 'phone', 'resume', 'cover_letter'];
    if (commonQuestions.includes(question?.fields[0]?.name)) {
      return null;
    }

    const inputType = question?.fields[0]?.type;
    switch (inputType) {
      case 'input_text':
      case 'textarea': {
        return (
          <TextField
            data-test="CustomQuestionTextField"
            key={question?.label}
            label={question?.label}
            isRequired={question?.required}
            onChange={(value) => onTextInputChange(value, question?.fields[0]?.name)}
            onBlur={(e) => onBlur(e.target.value, question?.fields[0]?.name, question?.required)}
            helperText={question?.required ? 'Required*' : ''}
            isError={formErrors[question?.fields[0]?.name]}
            isTextArea={inputType === 'textarea'}
          />
        );
      }
      case 'multi_value_single_select': {
        return (
          <RadioGroup
            data-test="CustomQuestionRadioGroup"
            key={question?.label}
            label={question?.label}
            items={question?.fields[0]?.values}
            name={question?.fields[0]?.name}
            isRequired={question?.required}
            helperText="Required*"
            isError={formErrors[question?.fields[0]?.name]}
            onChange={(value) => onTextInputChange(value, question?.fields[0]?.name)}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  // handles form submit process.
  const onFormSubmit = async () => {
    try {
      // set error if any required field is not filled.
      questions.forEach((question) => {
        if (
          question.required &&
          ((question.fields[0].type === 'input_file' &&
            !formData[`${question.fields[0].name}_content`]) ||
            (question.fields[0].type !== 'input_file' && !formData[question.fields[0].name]))
        ) {
          formErrors[question.fields[0].name] = true;
        }
      });

      if (educationRequired) {
        // set error if any degree dropdown is remaining.
        const tempEducations = educations;
        educations.forEach((education, index) => {
          if (!education?.value) {
            tempEducations[index].error = true;
          }
        });
        setEducations([...tempEducations]);
      }

      // if any required field is not filled, show error message and return.
      if (
        Object.values(formErrors).some((error) => error === true) ||
        educations.some((obj) => obj.error)
      ) {
        setErrorMessage('Please provide required fields*');
        return;
      }

      // set education data in formData.
      formData.educations = educations.map((obj) => ({ degree_id: obj.value }));

      // add auth query param in url.
      const url = new URL(
        `${GATSBY_LAMBDA_GREENHOUSE_PROXY}${GATSBY_GREENHOUSE_BASE_URL}jobs/${jobId}`,
      );
      url.searchParams.append('auth', `Basic ${GATSBY_GREENHOUSE_API_KEY}`);
      setIsFormSubmitting(true);

      // if all required fields are filled, submit the form.
      await fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSuccessMessage('Your application has been submitted successfully.');
    } catch (error) {
      setErrorMessage('Something went wrong, please try again later.');
    }
    setIsFormSubmitting(false);
  };

  // fetch education degrees from greenhouse.
  useEffect(() => {
    if (educationRequired) {
      fetch(`${GATSBY_GREENHOUSE_BASE_URL}education/degrees`)
        .then((res) => res.json())
        .then((res) => {
          if (res?.items) {
            const tempDegrees = res?.items.map((degree) => ({
              name: degree.text,
              value: degree.id,
            }));
            setDegrees(tempDegrees);
          } else {
            setDegrees([]);
          }
        })
        .catch(() => {
          setDegrees([]);
        });
    }
  }, [educationRequired, GATSBY_GREENHOUSE_BASE_URL]);

  // get required fields from questions array.
  useEffect(() => {
    const tempObject = {};
    if (questions?.length > 0) {
      questions.forEach((obj) => {
        tempObject[obj.fields[0].name] = obj?.required ?? false;
      });
      setRequiredFields(tempObject);
    }
  }, [questions]);

  // Show loading spinner while API is processing.
  if (!title) {
    return (
      <div className={s.loaderContainer} data-test="loadingSpinnerContainer">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className={s.pageContainer} data-test="pageContainer">
      <div className={s.bgGray}>
        <div className={s.pageHeader}>
          <h2 data-aos="fade-up" data-test="title">
            {title}
          </h2>
          <p data-aos="fade-up" data-aos-delay="100" data-test="locationAndEmploymentType">
            {`${employmentType} • ${location}`}
          </p>
          <div data-aos="fade-up" data-aos-delay="200">
            <a href="/careers">View All Jobs</a>
          </div>
        </div>
      </div>
      <div className={s.pageContent}>
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          className={s.description}
          data-test="description"
        >
          {parse(parse(description), { trim: true })}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          id="applicationForm"
          className={s.applicationForm}
          data-test="applicationFormWrapper"
        >
          <div className={s.formHeader}>
            <h3>Apply for this job</h3>
          </div>
          <div className={s.questionsContainer}>
            <div className={s.bottomSeparator}>
              <div className={s.nameRow}>
                <TextField
                  data-test="FirstNameTextField"
                  label="First Name"
                  isRequired
                  onChange={(value) => onTextInputChange(value, 'first_name')}
                  onBlur={(e) => onBlur(e.target.value, 'first_name', true)}
                  helperText="Required*"
                  isError={formErrors.first_name}
                  placeholder="first name"
                />
                <TextField
                  data-test="LastNameTextField"
                  label="Last Name"
                  isRequired
                  onChange={(value) => onTextInputChange(value, 'last_name')}
                  onBlur={(e) => onBlur(e.target.value, 'last_name', true)}
                  helperText="Required*"
                  isError={formErrors.last_name}
                  placeholder="last name"
                />
              </div>
              <TextField
                data-test="EmailTextField"
                label="Email"
                isRequired
                onChange={(value) => onTextInputChange(value, 'email')}
                onBlur={(e) => onBlur(e.target.value, 'email', true)}
                helperText="Required*"
                isError={formErrors.email}
                placeholder="name@email.com"
              />
              <TextField
                data-test="PhoneTextField"
                label="Phone"
                onChange={(value) => onTextInputChange(value, 'phone')}
                onBlur={(e) => onBlur(e.target.value, 'phone', requiredFields?.phone)}
                helperText="Required*"
                isError={formErrors.phone}
                placeholder="+1 (234) 567 9999"
                isRequired={requiredFields?.phone}
              />
              <div className={s.InputFileContainer}>
                <div>
                  <p>{`Resume/CV${requiredFields?.resume ? ' *' : ''}`}</p>
                  <p className={s.caption}>(File types: pdf, doc, docx)</p>
                </div>
                <div className={s.buttonContainer}>
                  <Button data-test="ResumeUploadButton" onClick={handleResumeSubmit}>
                    <AttachmentPinIcon />
                    Select Resume
                  </Button>
                  {formData?.resume_content_filename && (
                    <p className={s.caption}>{formData?.resume_content_filename}</p>
                  )}
                </div>
                <input
                  className={s.fileInput}
                  type="file"
                  onChange={handleHiddenInputChange}
                  ref={hiddenFileInput}
                  accept=".doc,.docx,.pdf"
                />
              </div>
              {formErrors?.resume && <p className={s.helperText}>Required *</p>}
            </div>
            {educationRequired && (
              <div className={classNames(s.educationContainer, s.bottomSeparator)}>
                {educations.map((education, index) => (
                  <Select
                    data-test="Educations"
                    key={education.value}
                    value={education.value}
                    label="Degree *"
                    placeholder="Select degree"
                    items={degrees}
                    helperText="Required *"
                    isError={education?.error}
                    onChange={(e) => onEducationValueChange(e.target.value, index)}
                  />
                ))}
                <Button
                  theme="text"
                  onClick={() => {
                    setEducations((prev) => [...prev, { value: null }]);
                  }}
                >
                  + Add another education
                </Button>
              </div>
            )}
            <div className={s.customQuestions}>
              {questions.map((question) => {
                return getInputField(question);
              })}
            </div>
          </div>
          {!isFormSubmitting && (
            <Button data-test="SubmitButton" onClick={onFormSubmit}>
              Submit Application
            </Button>
          )}
          {isFormSubmitting && <LoadingSpinner />}
          {errorMessage && <p className={s.errorMessage}>{errorMessage}</p>}
          {successMessage && <p className={s.successMessage}>{successMessage}</p>}
        </div>
      </div>
    </div>
  );
};

JobDetails.propTypes = propTypes;
JobDetails.defaultProps = defaultProps;
export default JobDetails;
