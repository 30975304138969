import React from 'react';
import PropTypes from 'prop-types';
import s from './TextField.module.scss';

const propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isError: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  isTextArea: PropTypes.bool,
  rows: PropTypes.number,
};

const defaultProps = {
  label: '',
  isRequired: false,
  onChange: () => {},
  onBlur: () => {},
  isError: false,
  helperText: '',
  placeholder: 'Type here',
  isTextArea: false,
  rows: 3,
};

const TextField = ({
  label,
  placeholder,
  isRequired,
  onChange,
  onBlur,
  isError,
  helperText,
  isTextArea,
  rows,
}) => {
  const onInputChange = (e) => {
    if (typeof onChange === 'function') onChange(e.target.value);
  };
  return (
    <div className={s.container}>
      <p htmlFor={label} className={s.label}>
        {label}
        {isRequired && <span>*</span>}
      </p>
      {!isTextArea && (
        <input
          onChange={onInputChange}
          onBlur={onBlur}
          className={s.input}
          type={label.toLowerCase() === 'email' ? 'email' : 'text'}
          name={label}
          placeholder={placeholder}
          required={isRequired}
        />
      )}
      {isTextArea && (
        <textarea
          onChange={onInputChange}
          onBlur={onBlur}
          className={s.input}
          type={label.toLowerCase() === 'email' ? 'email' : 'text'}
          name={label}
          placeholder={placeholder}
          required={isRequired}
          rows={rows}
        />
      )}

      {isError && <p className={s.helperText}>{helperText}</p>}
    </div>
  );
};

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;
export default TextField;
