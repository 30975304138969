import React from 'react';
import PropTypes from 'prop-types';
import s from './RadioGroup.module.scss';

const propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  isError: PropTypes.bool,
  helperText: PropTypes.string,
};

const defaultProps = {
  label: '',
  isRequired: false,
  isError: false,
  helperText: '',
  onChange: () => {},
};

const RadioGroup = ({ label, items, name, isRequired, isError, helperText, onChange }) => {
  const onValueChange = (e) => {
    if (typeof onChange === 'function') {
      onChange(e.target.id);
    }
  };

  return (
    <div className={s.container}>
      <p>{`${label}${isRequired ? ' *' : ''}`}</p>
      <div onChange={onValueChange} className={s.content}>
        {items.map((item) => {
          return (
            <div key={item.value} className={s.radioContainer}>
              <input type="radio" name={name} id={item.value} />
              <p className={s.radioLabel}>{item.label}</p>
            </div>
          );
        })}
      </div>
      {isError && <p className={s.helperText}>{helperText}</p>}
    </div>
  );
};

RadioGroup.propTypes = propTypes;
RadioGroup.defaultProps = defaultProps;
export default RadioGroup;
