import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import JobDetails from '../../containers/JobDetails';
import Layout from '../../components/Layout';
import CompanyQuote from '../../components/CompanyQuote';

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      footerDescription: PropTypes.shape({}).isRequired,
      icons: PropTypes.arrayOf(
        PropTypes.shape({
          fluid: PropTypes.shape({}),
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  params: PropTypes.shape({
    '*': PropTypes.string,
  }).isRequired,
};

const JobDetailsPage = ({ data, params }) => {
  const { GATSBY_GREENHOUSE_BASE_URL } = process.env;
  const jobId = params['*'];
  const [jobDetails, setJobDetails] = useState({});

  // fetch job details from greenhouse.
  useEffect(() => {
    if (!jobId) {
      navigate('/404');
    } else {
      fetch(`${GATSBY_GREENHOUSE_BASE_URL}jobs/${jobId}?questions=true`)
        .then((res) => res.json())
        .then((res) => {
          if (res?.title) {
            setJobDetails(res);
          } else {
            navigate('/404');
          }
        })
        .catch(() => {
          navigate('/404');
        });
    }
  }, [jobId, GATSBY_GREENHOUSE_BASE_URL]);

  return (
    <Layout currentPage="/careers/job">
      <JobDetails
        jobId={jobId}
        title={jobDetails?.title}
        location={jobDetails?.location?.name}
        employmentType={jobDetails?.metadata?.[0]?.value}
        description={jobDetails?.content}
        questions={jobDetails?.questions}
        educationRequired={Boolean(jobDetails?.education)}
      />
      <CompanyQuote companyQuote={data?.contentfulCompanyQuote} />
    </Layout>
  );
};

JobDetailsPage.propTypes = propTypes;
export default JobDetailsPage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
